import styles from './GiftPage.module.css';
import {useParams} from "react-router-dom";
import React, {useCallback, useEffect, useState} from "react";
import {GET} from "../../util/http";
import userStore from "../../store/userStore";
import ErrorPage from "../error/ErrorPage";
import {Unity, useUnityContext, UnityContent} from "react-unity-webgl";
import GiftPageLoading from "../../components/gift/GiftPageLoading";
import GiftStart from "../../components/gift/GiftStart";
import GiftEnd from "../../components/gift/GiftEnd";
import {sendEvent} from "../../util/firebase-analytics";

let isFetchingGift = false;
let didCallGiftInit = false;

// Steps Map
// -1 Error
// 0 Loading
// 1 First Step
// 2 Second Step
const GiftPage = (props) => {

    useEffect(()=>{
        // Page Open Analytics
        sendEvent('screenview', {screen_name: 'gift'})
    },[])

    const params = useParams();
    const [giftData, setGiftData] = useState(null);
    const [step, setStep] = useState(0);

    useEffect(() => {
        if (isFetchingGift) return;
        if (userStore.value.token && giftData == null) {
            isFetchingGift = true;
            GET('gifts/find/' + params.giftId).then((giftRes) => {
                console.log('ReqStatus', giftRes);
                if (giftRes.status !== 200)
                    setStep(-1);
                else {
                    setGiftData(giftRes.gift);
                    setStep(1);
                }
            });
        }
    }, [userStore.value.token])


    const {unityProvider, sendMessage, isLoaded, addEventListener, removeEventListener} =
        useUnityContext({
            loaderUrl: "/unity/Build/magic_gift.loader.js",
            dataUrl: "/unity/Build/magic_gift.data",
            frameworkUrl: "/unity/Build/magic_gift.framework.js",
            codeUrl: "/unity/Build/magic_gift.wasm",
            streamingAssetsUrl: "StreamingAssets",
            companyName: "txt_gift",
            productName: "txt_gift_gift_viewer",
            productVersion: "1.0",
        });

    const handleOpenTheGift = () => {
        // Tell Unity To Open The Gift
        sendMessage("Main", "OpenGift");
    }

    const handleGiftOpened = useCallback(() => {
        setStep(2);
    }, []);

    useEffect(() => {
        addEventListener("OnGiftOpened", handleGiftOpened);
        return () => {
            removeEventListener("OnGiftOpened", handleGiftOpened);
        };
    }, [isLoaded, addEventListener, removeEventListener, handleGiftOpened]);


    if (isLoaded && giftData) {
        let giftType = "circle_box_blue";
        if (giftData && giftData.hasOwnProperty('typeName'))
            giftType = giftData['typeName'];
        sendMessage('Main', 'SetMainCamera');

        console.log('giftData', giftData);
        if (!didCallGiftInit) {
            didCallGiftInit = true;
            sendMessage("Main", "InitGift", giftType);
        }
    }
    return <div className={styles.container}>
        {/*FIRST GIFT PAGE*/}
        <Unity unityProvider={unityProvider} devicePixelRatio={1} style={{width: '100%', height: '100%', position: 'fixed', marginTop: "-5rem", pointerEvents: 'none'}}/>
        {(step === 1 || step === 2) && <div className={styles.BackgroundGradientGiftPage}/>}
        {step === 1 && <GiftStart onGiftOpened={handleOpenTheGift}/>}
        {step === 2 && <GiftEnd gift={giftData}/>}
        {step === 0 && <GiftPageLoading/>}
        {step === -1 && <ErrorPage/>}
    </div>

}
export default GiftPage;
